.optionSlider{
    display: flex; 
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
}

.sliderTitle{
    font-size: 16px; 
    margin-right: 10px;
    text-align: left;
}

.sliderMin{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 5px; 
    margin-left: 5px; 
}

.sliderMax{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5px; 
 }

 .MuiSlider-root{
    color: #2196F3 !important;
    height: 10px !important; 
 }

 .MuiSlider-track{
    height: 10px !important; 
    border-radius: 4px !important;
 }

 .MuiSlider-rail{
    height: 10px !important; 
    border-radius: 4px !important;
 }

 .MuiSlider-thumb{
    margin-top: -4px !important;
    width: 18px !important; 
    height: 18px !important;
 }