/*Styling for the blurb popups*/ 
.modal{
    position: fixed;
    width: 350px;
    height: auto;
    z-index: 15;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -175px;
    padding: 15px; 
    background-color: white;
    border: none; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal-title{
    padding: 10px; 
    width: 100%; 
    font-weight: 700;
    text-align: center;
}

.modal-text{
    padding: 10px; 
    font-size: 12px; 
    line-height: 1.3; 
}

.x{
    position: absolute;
    right: 0%;
    top: 0%; 
    padding: 10px; 
    color: red; 
}