.mega-factor-box-holder{
    width: 100%; 
}

.factor-housing{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
    justify-content: left;
    padding: 0px; 
    border-top-style: solid;
    border-left-style: solid;  
    border-width: 1.2px;
}

.what-might{
    width: 100%;
    text-align: left;
    font-size: 2vw;
    padding:2vw; 
}

@media screen and (min-width: 900px) {
	.what-might {
        font-size: 20px;
        padding:20px; 
	}
} 