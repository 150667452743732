.footer-container{
    display: flex;
    flex-direction: column;
    font-family: "Roboto Condensed";
    font-size: 12px;
    color: rgba(0, 0, 0, .6); 
    width: 100%;
    padding-bottom: 2%;
    padding-top:4%;
}
.footer-text{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 2%;
}

.footer-text-2{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 2%;
}

.footer-text-3{
    padding-left: 10px;
}

.bold{
    font-weight: 700; 
}