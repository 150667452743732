.faqs-container{
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-family: "Roboto Condensed";
    padding: 10px; 
    padding-top: 35px; 
    font-size: 18px; 
    color: rgba(0, 0, 0, .6); 

}

.faq-title{
    padding-top: 10px; 
    font-size: 18px; 
}

.faq-text{
    padding-top: 10px;
    padding-bottom: 10px; 
    font-size:  14px; 
}