@font-face {
    font-family: 'Sailec';
    font-style: italic;
    font-weight: bold;
    src: url('http://srlinfotech.com/assets/fonts/Sailec-Bold-Italic.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec-Bold-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Sailec';
    font-style: normal;
    font-weight: bold;
    src: url('http://srlinfotech.com/assets/fonts/Sailec-Bold.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Sailec';
    font-style: italic;
    font-weight: 300;
    src: url('http://srlinfotech.com/assets/fonts/Sailec-Light-Italic.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Sailec';
    font-style: normal;
    font-weight: 300;
    src: url('http://srlinfotech.com/assets/fonts/Sailec-Light.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec-Light.woff') format('woff');
}

@font-face {
    font-family: 'Sailec';
    font-style: italic;
    font-weight: normal;
    src: url('http://srlinfotech.com/assets/fonts/Sailec-Regular-Italic.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec-Regular-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Sailec';
    font-style: normal;
    font-weight: normal;
    src: url('http://srlinfotech.com/assets/fonts/Sailec.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec.woff') format('woff');
}