@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap');



.simulator-container{
    display: flex;
    flex-direction: column;
    position: relative;
    align-content: center;
    align-items: center; 
    width: 60%;
    left: 20%; 
    padding-top: 2.5%; 
}


.dis{
    color: red;
    font-style: italic; 
}


.simulator-heading{
    text-align: center;
    font-weight: bold; 
    font-family: "Roboto Condensed";
    padding-bottom: 20px; 
    font-size: 30px; 
    background-color: #bbdefb;
    width: 85%;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.4);
}

.sticky-content{
    display: flex; 
    flex-direction: row;
    gap: 20px; 
    justify-content: center;
    background-color: #bbdefb;
    width: 85%; 
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 10; 
    margin-top: 20px; 
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.4);

}

.simulator-introtext{
    text-align: left;
    font-size: 16px; 
    position: relative;
    font-family: "Roboto Condensed";
    color: rgba(0, 0, 0, .8); 
    width: 70%;
}

.factors-container{
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 20px; 
    width: 85%; 
    min-height: 150px;

}

.factors-sub-container{
    padding-bottom: 20px; 
}

.factors-title{
    font-weight: bold;
    text-align: center;
    padding-top: 3vw;
    padding-bottom: 3vw;
    width: 100%;
    font-size: 2.5vw; 
    font-family: "Roboto Condensed"

}

@media screen and (min-width: 900px) {
	.factors-title {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 25px; 
	}
} 

.score-update-housing{
    display: flex;
    flex-direction: column;
    padding: 20px; 
    justify-content: center;
    align-items: center;
}

.score-update-text{
    text: bold;
    margin-bottom: 20px;
    
}

.score-update-explain{
    margin-bottom: 20px;
}


.why-button{
    background-color: darkgrey; 
    color: white;
    font-size: 20px;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}

.blurb-button-holder{
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 20px; 
    justify-content: flex-end;
    padding-bottom: 10px;
}

.blurb-button{
    position: relative;

}

.blurb-button .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    margin-left: 10px;
    border-radius: 6px;
    font-size: 10px; 
   
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
  }
  
  .blurb-button:hover .tooltiptext {
    visibility: visible;
  }


.arrow,
.arrow:before{
  position: absolute;
  left: 50%;
}

.arrow{
  width: 40px;
  height: 40px;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px red solid;
  border-bottom: 2px red solid;
}

  
.arrow:before{
  content: '';
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px red solid;
  border-bottom: 1px red solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}
  
@keyframes arrow{
  0%{
    opacity: 1;
  }100%{
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}


 


