
.factor{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3vw;
    border-bottom-style: solid;
    border-right-style: solid; 
    border-width: 1.2px; 
    -webkit-transition: background-color .5s; /* For Safari 3.0 to 6.0 */
    transition: background-color  .5s; /* For modern browsers */
    font-size: 2vw;
}

.factor:hover{
  background-color: #bbdefb;
}


.factor-title{
  text-align: left;
  min-width: 35%; 
}

.factor-icon{
  width: 5vw;
  height: auto; 
  padding-right: 1vw; 
}

@media screen and (min-width: 900px) {
	.factor-icon {
    width: 50px;
    height: auto; 
    padding-right: 10px;
	}

	.factor{
		font-size: 18px; 
	}
}