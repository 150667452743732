.input-housing{
    display: flex;
    flex-direction: column;
    padding: 2vw; 
    justify-content: center;
    align-items: center;
}

.input-info{
    display: flex;
    flex-direction: row;
    padding: 1vw;
    justify-content: center;
    align-items: center;

}

@media screen and (min-width: 900px) {
    .input-housing{
        padding: 20px;
    }

    .input-info{
        padding: 10px;
    }
}

.input-icon{
    padding-right: 3vw; 

}

.input-icon img{
    height: 10vw !important; 
    width: 10vw !important;
}

@media screen and (min-width: 900px) {
	.input-icon {
        padding-right: 30px; 
	}

	.input-icon img{
		height: 100px !important; 
        width: 100px !important;
	}
}

.input-text{
    font-size: 2vw; 
}

.input-title{
    font-weight: bold;
    text-align: center;
    padding-top: 3vw;
    padding-bottom: 3vw;
    width: 100%;
    font-size: 2.5vw;
    font-family: "Roboto Condensed";
}

.submit-button{
    background-color: rgb(87, 154, 87);
    color: white;
    font-size: 2vw;
    padding: 1vw 6vw;
    border-radius: .5vw;
    margin: 1vw 0px;
    cursor: pointer;
}

@media screen and (min-width: 900px) {
	.input-title {
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 25px;
	}

    .input-text{
        font-size: 16px; 
    }

    .submit-button{
        font-size: 20px;
        padding: 10px 60px;
        border-radius: 5px;
        margin: 10px 0px;
    }
}



.wrong-text{
    color: red; 
}