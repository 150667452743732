.option-housing{
    flex: 1 1 0px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    padding: 1vw; 
    margin: 1vw; 
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.4);
    -webkit-transition: background-color .5s; /* For Safari 3.0 to 6.0 */
    transition: background-color  .5s; /* For modern browsers */
}

.option-housing:hover{
    background-color: #bbdefb;
}

.option-icon{
    width: 7.5vw;
    height: 7.5vw; 
}

.option-icon-housing{
    padding: 1vw; 

}

.option-title{
    font-size: 2vw;
}

@media screen and (min-width: 900px) {
	.option-icon{
        width: 75px;
        height: 75px; 
    }
    
    .option-icon-housing{
        padding: 10px; 
    
    }

    .option-housing{
        padding: 10px; 
        margin: 10px;
    }

    .option-title{
        font-size: 16px;
    }
}

.row-of-options{
    display: flex;
    flex-direction: row; 
    width:80%;
    margin-left: 10%;
    justify-content: center;
}