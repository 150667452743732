.input-housing{
    display: flex;
    justify-content: left;
    align-items: center;
}

.input-title{
    margin-right: 15px;
    text-align: center;
    font-size: 16px;
}

input{
    border: hidden;
    background-color: rgb(177, 177, 177, .3);
    font-weight: 400;
    padding-bottom: 2px; 
    font-size: 20px; 
    outline: none;
}

input:focus {
    border-bottom: solid; 
    padding-bottom: 1px; 
    border-color: #2196F3;
}