.x-button-holder{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 1vw;
    position: relative;
    top: 0;
}

.x-button{
    position: relative;
    padding-right: 1vw;
    padding-top:1vw;
    color: red;

}

.x-button .tooltiptext {
    visibility: hidden;
    width: 12vw;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: .5vw 0;
    margin-left: 1vw;
    border-radius: 6vw;
    font-size: 1.5vw; 
   
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 105%;
  }
  
  .x-button:hover .tooltiptext {
    visibility: visible;
  }

  @media screen and (min-width: 900px) {
    .x-button-holder{
      padding-bottom: 10px;
    }

  .x-button{
    padding-right: 10px;
    padding-top:10px;
  }

  .x-button .tooltiptext {
    width: 120px;
    padding: 5px 0;
    margin-left: 10px;
    border-radius: 6px;
    font-size: 10px; 
  }
  } 