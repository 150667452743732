.intro-wrapper{
    height: 100%; 
}

.intro-housing{
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.4);
    padding: 20px; 
    height: 100vh; 
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome{
    font-size: 3vh; 
    padding-bottom: 20px;
}

.simulator-introtext{
	font-size: 2vh;
}

@media screen and (min-width: 1200px) {
	.welcome {
	  font-size: 30px;
	}

	.simulator-introtext{
		font-size: 20px; 
	}
}

.intro-text-wrapper{
    width: 60%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px; 
	white-space: pre-line;
}

.scroll {
	width: 60px;
	height: 60px;
	border: 2px solid #333;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
    position: absolute;
    bottom: 10px;
}
.scroll::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #333;
  	    border-bottom: 2px solid #333;
		transform: rotate(-45deg);
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

.callouts{
	width:50vw; 
	height: auto;
}