@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Sailec';
    font-style: italic;
    font-weight: bold;
    src: url('http://srlinfotech.com/assets/fonts/Sailec-Bold-Italic.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec-Bold-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Sailec';
    font-style: normal;
    font-weight: bold;
    src: url('http://srlinfotech.com/assets/fonts/Sailec-Bold.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Sailec';
    font-style: italic;
    font-weight: 300;
    src: url('http://srlinfotech.com/assets/fonts/Sailec-Light-Italic.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Sailec';
    font-style: normal;
    font-weight: 300;
    src: url('http://srlinfotech.com/assets/fonts/Sailec-Light.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec-Light.woff') format('woff');
}

@font-face {
    font-family: 'Sailec';
    font-style: italic;
    font-weight: normal;
    src: url('http://srlinfotech.com/assets/fonts/Sailec-Regular-Italic.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec-Regular-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Sailec';
    font-style: normal;
    font-weight: normal;
    src: url('http://srlinfotech.com/assets/fonts/Sailec.woff2') format('woff2'),
        url('http://srlinfotech.com/assets/fonts/Sailec.woff') format('woff');
}
body{
    background-color: #e3f2fd;
}

.score-housing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faqs-container{
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-family: "Roboto Condensed";
    padding: 10px; 
    padding-top: 35px; 
    font-size: 18px; 
    color: rgba(0, 0, 0, .6); 

}

.faq-title{
    padding-top: 10px; 
    font-size: 18px; 
}

.faq-text{
    padding-top: 10px;
    padding-bottom: 10px; 
    font-size:  14px; 
}
.footer-container{
    display: flex;
    flex-direction: column;
    font-family: "Roboto Condensed";
    font-size: 12px;
    color: rgba(0, 0, 0, .6); 
    width: 100%;
    padding-bottom: 2%;
    padding-top:4%;
}
.footer-text{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 2%;
}

.footer-text-2{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 2%;
}

.footer-text-3{
    padding-left: 10px;
}

.bold{
    font-weight: 700; 
}
.simulator-container{
    display: flex;
    flex-direction: column;
    position: relative;
    align-content: center;
    align-items: center; 
    width: 60%;
    left: 20%; 
    padding-top: 2.5%; 
}


.dis{
    color: red;
    font-style: italic; 
}


.simulator-heading{
    text-align: center;
    font-weight: bold; 
    font-family: "Roboto Condensed";
    padding-bottom: 20px; 
    font-size: 30px; 
    background-color: #bbdefb;
    width: 85%;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.4);
}

.sticky-content{
    display: flex; 
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px; 
    justify-content: center;
    background-color: #bbdefb;
    width: 85%; 
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 10; 
    margin-top: 20px; 
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.4);

}

.simulator-introtext{
    text-align: left;
    font-size: 16px; 
    position: relative;
    font-family: "Roboto Condensed";
    color: rgba(0, 0, 0, .8); 
    width: 70%;
}

.factors-container{
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 20px; 
    width: 85%; 
    min-height: 150px;

}

.factors-sub-container{
    padding-bottom: 20px; 
}

.factors-title{
    font-weight: bold;
    text-align: center;
    padding-top: 3vw;
    padding-bottom: 3vw;
    width: 100%;
    font-size: 2.5vw; 
    font-family: "Roboto Condensed"

}

@media screen and (min-width: 900px) {
	.factors-title {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 25px; 
	}
} 

.score-update-housing{
    display: flex;
    flex-direction: column;
    padding: 20px; 
    justify-content: center;
    align-items: center;
}

.score-update-text{
    text: bold;
    margin-bottom: 20px;
    
}

.score-update-explain{
    margin-bottom: 20px;
}


.why-button{
    background-color: darkgrey; 
    color: white;
    font-size: 20px;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}

.blurb-button-holder{
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 20px; 
    justify-content: flex-end;
    padding-bottom: 10px;
}

.blurb-button{
    position: relative;

}

.blurb-button .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    margin-left: 10px;
    border-radius: 6px;
    font-size: 10px; 
   
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
  }
  
  .blurb-button:hover .tooltiptext {
    visibility: visible;
  }


.arrow,
.arrow:before{
  position: absolute;
  left: 50%;
}

.arrow{
  width: 40px;
  height: 40px;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px red solid;
  border-bottom: 2px red solid;
}

  
.arrow:before{
  content: '';
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px red solid;
  border-bottom: 1px red solid;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: arrow;
          animation-name: arrow;
}
  
@-webkit-keyframes arrow{
  0%{
    opacity: 1;
  }100%{
    opacity: 0;
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
  }
}
  
@keyframes arrow{
  0%{
    opacity: 1;
  }100%{
    opacity: 0;
    -webkit-transform: translate(-10px, -10px);
            transform: translate(-10px, -10px);
  }
}


 



.mega-factor-box-holder{
    width: 100%; 
}

.factor-housing{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
    justify-content: left;
    padding: 0px; 
    border-top-style: solid;
    border-left-style: solid;  
    border-width: 1.2px;
}

.what-might{
    width: 100%;
    text-align: left;
    font-size: 2vw;
    padding:2vw; 
}

@media screen and (min-width: 900px) {
	.what-might {
        font-size: 20px;
        padding:20px; 
	}
} 

.factor{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3vw;
    border-bottom-style: solid;
    border-right-style: solid; 
    border-width: 1.2px; /* For Safari 3.0 to 6.0 */
    transition: background-color  .5s; /* For modern browsers */
    font-size: 2vw;
}

.factor:hover{
  background-color: #bbdefb;
}


.factor-title{
  text-align: left;
  min-width: 35%; 
}

.factor-icon{
  width: 5vw;
  height: auto; 
  padding-right: 1vw; 
}

@media screen and (min-width: 900px) {
	.factor-icon {
    width: 50px;
    height: auto; 
    padding-right: 10px;
	}

	.factor{
		font-size: 18px; 
	}
}
.option-housing{
    flex: 1 1;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    padding: 1vw; 
    margin: 1vw; 
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.4); /* For Safari 3.0 to 6.0 */
    transition: background-color  .5s; /* For modern browsers */
}

.option-housing:hover{
    background-color: #bbdefb;
}

.option-icon{
    width: 7.5vw;
    height: 7.5vw; 
}

.option-icon-housing{
    padding: 1vw; 

}

.option-title{
    font-size: 2vw;
}

@media screen and (min-width: 900px) {
	.option-icon{
        width: 75px;
        height: 75px; 
    }
    
    .option-icon-housing{
        padding: 10px; 
    
    }

    .option-housing{
        padding: 10px; 
        margin: 10px;
    }

    .option-title{
        font-size: 16px;
    }
}

.row-of-options{
    display: flex;
    flex-direction: row; 
    width:80%;
    margin-left: 10%;
    justify-content: center;
}
.optionSlider{
    display: flex; 
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
}

.sliderTitle{
    font-size: 16px; 
    margin-right: 10px;
    text-align: left;
}

.sliderMin{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 5px; 
    margin-left: 5px; 
}

.sliderMax{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5px; 
 }

 .MuiSlider-root{
    color: #2196F3 !important;
    height: 10px !important; 
 }

 .MuiSlider-track{
    height: 10px !important; 
    border-radius: 4px !important;
 }

 .MuiSlider-rail{
    height: 10px !important; 
    border-radius: 4px !important;
 }

 .MuiSlider-thumb{
    margin-top: -4px !important;
    width: 18px !important; 
    height: 18px !important;
 }
.input-housing{
    display: flex;
    justify-content: left;
    align-items: center;
}

.input-title{
    margin-right: 15px;
    text-align: center;
    font-size: 16px;
}

input{
    border: hidden;
    background-color: rgb(177, 177, 177, .3);
    font-weight: 400;
    padding-bottom: 2px; 
    font-size: 20px; 
    outline: none;
}

input:focus {
    border-bottom: solid; 
    padding-bottom: 1px; 
    border-color: #2196F3;
}
.input-housing{
    display: flex;
    flex-direction: column;
    padding: 2vw; 
    justify-content: center;
    align-items: center;
}

.input-info{
    display: flex;
    flex-direction: row;
    padding: 1vw;
    justify-content: center;
    align-items: center;

}

@media screen and (min-width: 900px) {
    .input-housing{
        padding: 20px;
    }

    .input-info{
        padding: 10px;
    }
}

.input-icon{
    padding-right: 3vw; 

}

.input-icon img{
    height: 10vw !important; 
    width: 10vw !important;
}

@media screen and (min-width: 900px) {
	.input-icon {
        padding-right: 30px; 
	}

	.input-icon img{
		height: 100px !important; 
        width: 100px !important;
	}
}

.input-text{
    font-size: 2vw; 
}

.input-title{
    font-weight: bold;
    text-align: center;
    padding-top: 3vw;
    padding-bottom: 3vw;
    width: 100%;
    font-size: 2.5vw;
    font-family: "Roboto Condensed";
}

.submit-button{
    background-color: rgb(87, 154, 87);
    color: white;
    font-size: 2vw;
    padding: 1vw 6vw;
    border-radius: .5vw;
    margin: 1vw 0px;
    cursor: pointer;
}

@media screen and (min-width: 900px) {
	.input-title {
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 25px;
	}

    .input-text{
        font-size: 16px; 
    }

    .submit-button{
        font-size: 20px;
        padding: 10px 60px;
        border-radius: 5px;
        margin: 10px 0px;
    }
}



.wrong-text{
    color: red; 
}
.intro-wrapper{
    height: 100%; 
}

.intro-housing{
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.4);
    padding: 20px; 
    height: 100vh; 
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.welcome{
    font-size: 3vh; 
    padding-bottom: 20px;
}

.simulator-introtext{
	font-size: 2vh;
}

@media screen and (min-width: 1200px) {
	.welcome {
	  font-size: 30px;
	}

	.simulator-introtext{
		font-size: 20px; 
	}
}

.intro-text-wrapper{
    width: 60%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px; 
	white-space: pre-line;
}

.scroll {
	width: 60px;
	height: 60px;
	border: 2px solid #333;
	border-radius: 50%;
	position: relative;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
    position: absolute;
    bottom: 10px;
}
.scroll::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #333;
  	    border-bottom: 2px solid #333;
		-webkit-transform: rotate(-45deg);
		        transform: rotate(-45deg);
}

@keyframes down {
	0% {
		-webkit-transform: translate(0);
		        transform: translate(0);
	}
	20% {
		-webkit-transform: translateY(15px);
		        transform: translateY(15px);
	}
	40% {
		-webkit-transform: translate(0);
		        transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		-webkit-transform: translate(0);
		        transform: translate(0);
	}
	20% {
		-webkit-transform: translateY(15px);
		        transform: translateY(15px);
	}
	40% {
		-webkit-transform: translate(0);
		        transform: translate(0);
	}
}

.callouts{
	width:50vw; 
	height: auto;
}
/*Styling for the blurb popups*/ 
.modal{
    position: fixed;
    width: 350px;
    height: auto;
    z-index: 15;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -175px;
    padding: 15px; 
    background-color: white;
    border: none; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal-title{
    padding: 10px; 
    width: 100%; 
    font-weight: 700;
    text-align: center;
}

.modal-text{
    padding: 10px; 
    font-size: 12px; 
    line-height: 1.3; 
}

.x{
    position: absolute;
    right: 0%;
    top: 0%; 
    padding: 10px; 
    color: red; 
}
.x-button-holder{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 1vw;
    position: relative;
    top: 0;
}

.x-button{
    position: relative;
    padding-right: 1vw;
    padding-top:1vw;
    color: red;

}

.x-button .tooltiptext {
    visibility: hidden;
    width: 12vw;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: .5vw 0;
    margin-left: 1vw;
    border-radius: 6vw;
    font-size: 1.5vw; 
   
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 105%;
  }
  
  .x-button:hover .tooltiptext {
    visibility: visible;
  }

  @media screen and (min-width: 900px) {
    .x-button-holder{
      padding-bottom: 10px;
    }

  .x-button{
    padding-right: 10px;
    padding-top:10px;
  }

  .x-button .tooltiptext {
    width: 120px;
    padding: 5px 0;
    margin-left: 10px;
    border-radius: 6px;
    font-size: 10px; 
  }
  } 
